
  // This file was autogenerated by dynamically running setEnv.ts and using dotenv for managing API key secrecy
  import { CacheLocation } from 'msal';
  export const environment = {
    production: true,
    version: '1.0.2',
    updateAt: 'Oct 22, 2024 10:35 AM',
    baseURL: 'https://api.aisi.jnj.com/api/v1/',
    entryAPI: 'https://api.aisi.jnj.com/api/v1/',
    mapApiKey: 'AIzaSyAk9F3eFlvJEyqTyN3EFDr3Bnch7OkWFvM',
    pageSize: 10,
    gridPageSize: [5, 10, 20],
    environment: 'staging',
    platformAdminADgroup: 'IOT-SIP-PLATFORMADMIN-PRD',
    STORAGE_DETAILS_ACCOUNTNAME:'undefined',
    STORAGE_DETAILS_SHARENAME:'undefined',
    STORAGE_DETAILS_SAS: 'undefined',
    STORAGE_DETAILS_ROOT_DIRECTORY_NAME: 'undefined',
    STORAGE_DETAILS_UPLOAD_SUB_DIRECTORY_NAME: 'undefined',
    STORAGE_DETAILS_REPORTS_SUB_DIRECTORY_NAME: 'undefined',
    authConfig: {
      config: {
        auth: {
          tenant: 'jnj.onmicrosoft.com',
          clientId: '6938838f-d57e-4f73-851e-c07b8918efb0',
          authority: 'https://login.microsoftonline.com/jnj.onmicrosoft.com',
          redirectUri: 'https://ui.aisi.jnj.com',
          postLogoutRedirectUri: 'https://ui.aisi.jnj.com/logout',
          navigateToLoginRequestUrl: false,
        },
        cache: {
          cacheLocation: 'localStorage' as CacheLocation,
          loadFrameTimeout: 30000,
        },
      },
      angularConfig: {
        consentScopes: [
          'user.read',
          'https://graph.microsoft.com',
          'https://graph.windows.net',
        ],
        extraQueryParameters: {},
      },
    },
    lockInterval: 30, // In minutes
    appURLs: {
      GDCdev: 'https://gdcdev.appserviceenvna.na.ase.jnj.com/GDC/',
    },
    msalConfiguration: {
      auth: {
        tenant: 'jnj.onmicrosoft.com',
        clientId: '6938838f-d57e-4f73-851e-c07b8918efb0',
        authority: 'https://login.microsoftonline.com/jnj.onmicrosoft.com',
        knownAuthorities: [],
        redirectUri: 'https://ui.aisi.jnj.com',
        postLogoutRedirectUri: 'https://ui.aisi.jnj.com/logout',
        navigateToLoginRequestUrl: true,
        cacheLocation: 'localStorage',
        loadFrameTimeout: 30000,
      },
    },
    msalLoginReq: {
      scopes: [ 'api://0760e066-b56d-46f4-8f4c-cfe38effca0b/user_impersonation'], // optional Array<string>
    },
    msalTokenReq: {
      scopes: [
         'api://0760e066-b56d-46f4-8f4c-cfe38effca0b/user_impersonation'
      ],
    }
  };
  